import React, { useState, useEffect } from "react";
import axios from "axios";
import ItemCard from "../components/ItemCard";
import ArticleList from "../components/ArticleList";
import Footer from "../components/Footer";


const HomePage = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const url =
          window.location.hostname === "localhost"
            ? "http://localhost:4000/data"
            : "/data";
  
        // Add a timestamp to the URL to prevent caching
        const response = await axios.get(url + `?t=${new Date().getTime()}`);
        
        setItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
  
    fetchData();
  
    const interval = setInterval(() => {
      fetchData(); // Fetch new data every 5 minutes
    }, 600000); // 600000 ms = 10 minutes
  
    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);
  

  const urlSources = {
    destructoid: "Destructoid",
    eurogamer: "Eurogamer",
    gamesradar: "GamesRadar+",
    gamespot: "GameSpot",
    "ign.com": "IGN",
    kotaku: "Kotaku",
    pcgamer: "PC Gamer",
    "polygon.com": "Polygon",
    rockpapershotgun: "Rock Paper Shotgun",
    vg247: "VG247",
  };

  // Group and limit articles by source
  const getFilteredItems = () => {
    const groupedBySource = items.reduce((acc, item) => {
      let sourceKey = Object.keys(urlSources).find((key) =>
        item.link.includes(key)
      );
      if (sourceKey) {
        if (!acc[sourceKey]) {
          acc[sourceKey] = [];
        }
        acc[sourceKey].push(item);
      }
      return acc;
    }, {});

    // Separate articles into latest and older
    const latestItems = Object.values(groupedBySource)
      .map((articles) => articles.slice(0, 3)) // Latest 3 articles per source
      .flat();

    const olderItems = Object.values(groupedBySource)
      .map((articles) => articles.slice(3, 8)) // Older 5 articles per source
      .flat();

    // Sort latest items by newest first
    const sortedLatestItems = latestItems.sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate));
    
    return { latestItems: sortedLatestItems.slice(0, 30), olderItems };
  };

  const { latestItems, olderItems } = getFilteredItems();

  // Group older items by source
  const groupOlderItemsBySource = (source) =>
    olderItems.filter((item) => {
      const key = Object.keys(urlSources).find((key) =>
        item.link.includes(key)
      );
      return urlSources[key] === source;
    });

  return (
    <>
      <main className="homepage mb-3">
        <div className="container px-4 px-md-0">
          <div className="row">
            {loading ? (
              <div className="spinner-overlay">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : latestItems.length === 0 ? (
              <p className="text-center">No items to display.</p>
            ) : (
              latestItems.map((item, index) => (
                <ItemCard key={index} item={item} index={index} />
              ))
            )}
          </div>

          {!loading && (
          <div className="row">
            <h3 className="mb-4">In case you missed it&hellip;</h3>
            {Object.values(urlSources).map((source) => (
              <div key={source} className="col-md-12 mb-4">
                <h4>{source}</h4>
                <ul className="list-unstyled">
                  {groupOlderItemsBySource(source).map((item, index) => (
                    <ArticleList key={index} item={item} index={index} />
                  ))}
                </ul>
              </div>
            ))}
          </div>
  )}
        </div>
      </main>
      {!loading && <Footer />}
    </>
  );
};

export default HomePage;

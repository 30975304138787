import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop.js';
import Header from './components/Header'; 
import HomePage from "./pages/HomePage";
import PrivacyNotice from "./pages/PrivacyNotice";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import './App.css';
// import Test from "./pages/Test";

function App() {

  return (
    <Router>

       <ScrollToTop />
       <Header />

      <Routes>
      <Route path="/"  element={<HomePage />} />
        <Route path="/privacy" element={<PrivacyNotice />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/test" element={<Test />} /> */}
      </Routes>

    </Router>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";

function Footer() {

const currentYear = new Date().getFullYear();

  return (
    <footer className="pb-5">
      <div className="container">
        <div className="row g-0">
          <div className="col-lg-6">
            <ul className="list-inline text-center text-lg-start mb-lg-0">
             <li className="list-inline-item">
              <Link to="/contact">Contact</Link>
              </li> 
              <li className="list-inline-item">
              <Link to="/terms">Terms</Link>
              </li>
              <li className="list-inline-item">
              <Link to="/privacy">Privacy Notice</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <p className="text-center text-lg-end mb-lg-0">
              &reg;Copyright {currentYear} Gamestrike
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

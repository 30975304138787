import React, { useState } from "react";
import axios from "axios";
import Footer from "../components/Footer";

const Dashboard = () => {
  const [zipCode, setZipCode] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [error, setError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };

  const validateZipCode = () => {
    const zipCodePattern = /^[0-9]{5}$/;
    if (!zipCodePattern.test(zipCode)) {
      setZipCodeError("Please enter a valid 5-digit ZIP code.");
      return false;
    }
    setZipCodeError("");
    return true;
  }; 

  const fetchWeatherData = () => {
    if (!validateZipCode()) {
      return;
    }
    axios
      //.get(`http://localhost:4000/api/currentWeather?address=${zipCode}`)
       .get(`/api/currentWeather?address=${zipCode}`)
      .then((response) => {
        setWeatherData(response.data);
        setError("");
      })
      .catch((error) => {
        console.error("Error fetching weather data:", error);
        setError("Error fetching weather data. Please try again.");
      });
  };

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'short', day: 'numeric' };
    const utcDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
    return utcDate.toLocaleDateString('en-US', options);
  };
  return (
    <>
      <main className="dashboard mb-5">
        <div className="container px-4 px-md-0">
          <div className="row">
            <div className="col-md-4 offset-md-4">
              <div className="input-group mb-3">
                <label htmlFor="zip" className="visually-hidden">Enter ZIP Code</label>
                <input
                  type="text"
                  id="zip"
                  value={zipCode}
                  onChange={handleZipCodeChange}
                  className="form-control"
                  placeholder="Enter ZIP Code"
                />
                 
                <button
                  className="btn btn-outline-secondary"
                  onClick={fetchWeatherData}
                >
                  Get Weather
                </button>
            
              </div>
            </div>
          </div>


          {zipCodeError && <p className="text-danger text-center">{zipCodeError}</p>}

          {weatherData && (
            <h2>Your Weather Outlook for {weatherData.address}</h2>
          )}

          <div className="row g-2">
            <div className="col-md-2">
              {weatherData && (
                <div
                  className={`card border-0 h-100 current ${weatherData.currentConditions.icon}`}
                >
                  <div className="card-body text-center text-white mb-3">
                    <p className="temp mb-2">
                      {Math.round(weatherData.currentConditions.temp)}°F
                    </p>
                    <p className="conditions mb-2">
                      {weatherData.currentConditions.conditions}
                    </p>
                    <p className="mb-0">
                      Feels like:{" "}
                      {Math.round(weatherData.currentConditions.feelslike)}°F{" "}
                    </p>

                    <p className="mb-0">Wind: {weatherData.currentConditions.windspeed} mph</p>
                  </div>
                </div>
              )}
            </div>

            {weatherData &&
              weatherData.days.slice(0, 5).map((day) => (
                <div key={day.datetime} className="col-md-2">
                  <div className="card border-0 h-100 forecast bg-transparent">
                    <div className="card-body text-center text-white">
                      <div className="row">
                        <div className="col-4 col-md-12 align-self-center"><p className="datetime mb-0 mb-md-3">{formatDate(day.datetime)}</p></div>
                        <div className="col-4 col-md-12 align-self-center"><img
                          src={`./images/${day.icon}.png`}
                          className="img-fluid mb-md-3"
                          alt=""
                        /></div>
                        <div className="col-4 col-md-12 align-self-center"><p className="mb-0">
                        {Math.round(day.tempmax)}° / {Math.round(day.tempmin)}°
                      </p></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {error && <p className="text-center">{error}</p>}
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Dashboard;

import React from 'react';
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Terms | GameStrike</title>
      </Helmet>

      <main className="mb-5">
        <div className="container px-4 px-md-0">
          <div className="row">
            <div className="offset-lg-2 col-lg-8">
              <h2 className="mb-2">Terms and Conditions</h2>

              <p className="mb-4">Last Updated: Nov 5, 2023</p>

              <p>
                Welcome to GameStrike, your source for the latest and greatest
                in video game news. Please read these Terms and Conditions
                carefully before using our website.
              </p>

              <h3>Acceptance of Terms</h3>
              <p>
                By accessing and using GameStrike, you agree to abide by these
                Terms and Conditions. If you do not agree to these terms, please
                refrain from using our website.
              </p>

              <h3>Use of Content</h3>
              <ul>
                <li>
                  GameStrike is a platform that aggregates and shares links to
                  video game news articles from various sources.
                </li>
                <li>
                  All content posted on our website is for informational
                  purposes only. We do not create or control the content of the
                  linked articles.
                </li>
                <li>
                  We do not guarantee the accuracy, completeness, or timeliness
                  of the news articles posted. Users should verify information
                  with the original source.
                </li>
                <li>
                  While we strive to provide reliable and up-to-date content, we
                  are not responsible for any inaccuracies, omissions, or errors
                  in the news articles.
                </li>
              </ul>

              <h3>Intellectual Property</h3>
              <ul>
                <li>
                  All content posted on GameStrike is the property of the
                  respective original sources, and they retain all rights to
                  their content.
                </li>
                <li>
                  GameStrike and its logo are the property of GameStrike and are
                  protected by copyright law. Unauthorized use of our branding
                  is prohibited.
                </li>
              </ul>

              <h3>Privacy</h3>
              <p>
                We take your privacy seriously. Please review our <a href="/privacy">Privacy Notice</a> for information on how we collect, use, and protect your
                personal information.
              </p>

              <h3>Disclaimer</h3>
              <p>
                GameStrike is not affiliated with any specific video game
                companies, and the content we provide is for general information
                purposes only. We do not endorse or promote any specific
                products or services mentioned in the news articles.
              </p>

              <h3>Liability</h3>
              <p>
                We do not accept liability for any loss, damage, or
                inconvenience caused by using our website or relying on the
                information provided. Users access GameStrike at their own risk.
              </p>

              <h3>Changes to Terms</h3>
              <p>
                We may update these Terms and Conditions in the future. Any
                changes will be posted on this page, and the date of the last
                update will be revised accordingly.
              </p>

              <h3>Contact Us</h3>
              <p>
                If you have any questions or concerns regarding these Terms and
                Conditions, please refer to our <a href="/contact"> contact page
                </a> for assistance.
              </p>

              <p>
                Thank you for using GameStrike as your source for video game
                news. We hope you enjoy our content and find it informative and
                entertaining.
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Terms;

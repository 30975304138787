import React from 'react';
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";


const PrivacyNotice = () => {

  return (
    <>
      <Helmet>
        <title>Privacy | GameStrike</title>
      </Helmet>

      <main className="mb-5">
        <div className="container">
          <div className="container px-4 px-md-0">
            <div className="row">
              <div className="offset-lg-2 col-lg-8">
                <h2 className="mb-2">Privacy Notice</h2>

                <p className="mb-4">Last Updated: Nov 5, 2023</p>

                <h3>Welcome to GameStrike!</h3>
                <p>
                  We value your privacy, and we want to be transparent about how
                  we handle your data. As of now, we do not collect any personal
                  information from visitors to our website. However, as we
                  expand and introduce new features in the future, we may
                  collect data to enhance your experience and offer more
                  personalized content.
                </p>

                <h3>Future Data Collection</h3>
                <p>
                  We may, in the future, introduce features that involve data
                  collection to provide you with a more customized experience.
                  Rest assured that we will update this Privacy Notice to
                  reflect any changes in our data collection practices. Your
                  privacy remains our priority, and any data we collect will be
                  handled in compliance with relevant laws and regulations.
                </p>

                <h3>Cookies and Tracking, Including Google Analytics</h3>
                <p>
                  Currently, we do not use cookies or tracking technologies on
                  our website, except for Google Analytics. Google Analytics is
                  a common web analytics tool that helps us understand how
                  visitors interact with our site. It collects data such as your
                  IP address, the pages you visit, and the time you spend on our
                  website. This data is collected anonymously and is used to
                  improve our website and provide a better user experience.
                </p>
                <p>
                  If we decide to implement additional tracking technologies in
                  the future, we will provide clear information on how they are
                  used and obtain your consent when required.
                </p>

                <h3>Changes to Our Privacy Notice</h3>
                <p>
                  We may update this Privacy Notice in the future to reflect any
                  changes in our practices. We are committed to keeping you
                  informed about how we handle your data.
                </p>
     
                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions or concerns about your privacy, please refer to our <a href="/contact">
                            contact page</a> for assistance..
                    </p>
    
                <p>
                  Thank you for using GameStrike. Your privacy is important to
                  us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PrivacyNotice;

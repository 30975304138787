import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";


const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    jobTitle: "",
    email: "",
    inquiryType: "",
    message: "",
    zip: "" // Honeypot field
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  // Determine the API URL based on the environment
  const isLocalhost = window.location.hostname === 'localhost';
  const apiUrl = isLocalhost
    ? "http://localhost:4000/send-email"
    : "/send-email";

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact | GameStrike</title>
      </Helmet>

      <main className="mb-5">
        <div className="container px-4 px-md-0">
          <div className="row">
            <div className="offset-lg-2 col-lg-8">
              <h2 className="mb-2">Contact</h2>

              <p className="mb-5">
                Do you have a question or a special request? We'd love to hear
                from you! Please take a moment to fill out the form below, and
                the GameStrike team will be in touch with you as soon as
                possible.
              </p>

              <div className="border border-mute rounded-4 p-3 p-md-5">
                {isSubmitted ? (
                  <>
                    <h3>Thank you!</h3>
                    <p>Your email was sent successfully.</p>
                  </>
                ) : isError ? (
                  <>
                    <h3>Oops!</h3>
                    <p>Something went wrong. Please try again later.</p>
                  </>
                ) : (
                  <>
                    <p className="text-md-end">*Required</p>

                    <form onSubmit={handleSubmit}>
                      <div class="form-floating mb-3">
                        <input
                            type="text"
                            id="firstName"
                            className="form-control"
                            placeholder="Enter your first name"
                            maxLength="50"
                            aria-required="true"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="firstName">First Name*</label>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                            type="text"
                            id="lastName"
                            className="form-control form-control-lg"
                            placeholder="Enter your last name"
                            maxLength="50"
                            aria-required="true"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="lastName">Last Name*</label>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          id="companyName"
                          className="form-control"
                          placeholder="Enter your company name."
                          maxLength="100"
                          value={formData.companyName}
                          onChange={handleChange}
                        />
                        <label htmlFor="companyName">Company Name</label>
                      </div>

                      <div class="form-floating mb-3 d-none">
                        <input
                              type="text"
                              id="zip"
                              className="form-control"
                              placeholder="Enter your ZIP"
                              maxLength="15"
                              value={formData.zip}
                              onChange={handleChange}
                        />
                        <label htmlFor="zip">ZIP Code</label>
                      </div>


                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          id="jobTitle"
                          className="form-control"
                          placeholder="Enter your job title"
                          maxLength="100"
                          value={formData.jobTitle}
                          onChange={handleChange}
                        />
                        <label htmlFor="jobTitle">Job Title</label>
                      </div>

                      <div class="form-floating mb-3">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Enter your email address"
                          maxLength="320"
                          aria-required="true"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="email">Email*</label>
                      </div>

                      <div class="form-floating mb-3">
                      <select
                            id="inquiryType"
                            className="form-select"
                            aria-required="true"
                            value={formData.inquiryType}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select an option</option>
                            <option>Advertising Inquiry</option>
                            <option>News Suggestion(s)</option>
                            <option>General Inquiry</option>
                          </select>
                        <label htmlFor="inquiryType">Inquiry Type*</label>
                      </div>


                      <div class="form-floating mb-3">
                      <textarea
                            id="message"
                            className="form-control"
                            placeholder="Leave your message here"
                            rows="4"
                            maxLength="1000"
                            aria-required="true"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            style={{ height: '100px' }}
                          />
                        <label htmlFor="message">Message*</label>
                      </div>

                      <div class="form-floating mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg w-100 py-3"
                          >
                            Submit
                          </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Contact;

import React, { useState, useEffect } from "react";
import './ArticleList.css';

const ArticleList = ({ item, index }) => {
  const [timeDiff, setTimeDiff] = useState("");

  useEffect(() => {
    // Calculate the time difference
    const pubDate = new Date(item.pubDate);
    const currentTime = new Date();
    const diff = currentTime - pubDate;

    // Convert milliseconds to seconds
    const seconds = Math.floor(diff / 1000);

    // Convert seconds to appropriate unit
    if (seconds < 15 * 60) {
      setTimeDiff("Now");
    } else if (seconds < 3600) {
      const mins = Math.floor(seconds / 60);
      setTimeDiff(`${mins} min${mins === 1 ? "" : "s"} ago`);
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      setTimeDiff(`${hours} hour${hours === 1 ? "" : "s"} ago`);
    } else {
      const days = Math.floor(seconds / 86400);
      setTimeDiff(`${days} day${days === 1 ? "" : "s"} ago`);
    }
  }, [item.pubDate]);

  function truncateSummary(summary) {
    const words = summary.split(" ");
    return words.length > 10 ? words.slice(0, 10).join(" ") + "..." : summary;
  }



  return (
<li key={index} className="article-item py-2">
  <div className="article-content">
    <a href={item.link} target="_blank" rel="noopener noreferrer" className="article-title">
      {truncateSummary(item.title)}
    </a>
    <span className="article-author">{item.author}</span>
  </div>
  <span className="article-time d-none d-md-block">{timeDiff}</span>
</li>

  );
};

export default ArticleList;
